import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { Tabs, TabsAlignment } from 'wix-ui-tpa/cssVars';
import { connect } from '../../../../common/components/runtime-context';
import withTranslate from '../../../../common/hoc/with-translate';
import { getIsUserGuestWriter } from '../../../../common/selectors/user-role-selectors';
import {
  MY_POSTS_PAGE_TAB_DRAFTS,
  MY_POSTS_PAGE_TAB_PUBLICATIONS,
  MY_POSTS_PAGE_TAB_SUBMISSIONS,
} from '../../../constants/my-posts-page';
import { getActiveTab } from '../../../store/active-tab/active-tab-selectors';
import { classes } from './page-tabs.st.css';

export const ALIGNMENT = TabsAlignment;

class PageTabs extends React.Component {
  constructor(props) {
    super(props);
    const submissionsTab = this.props.isUserGuestWriter
      ? [MY_POSTS_PAGE_TAB_SUBMISSIONS]
      : [];

    this.tabsByIndex = [
      MY_POSTS_PAGE_TAB_PUBLICATIONS,
      ...submissionsTab,
      MY_POSTS_PAGE_TAB_DRAFTS,
    ];
  }

  handleTabClick = async (tabIndex) => {
    const tab = this.tabsByIndex[tabIndex];
    if (tab) {
      await this.props.setActiveTab(tab);
      await this.props.fetchPageData();
    }
  };

  render() {
    const { t, activeTab, alignment = TabsAlignment.left, isUserGuestWriter } = this.props;
    const submissionsTab = isUserGuestWriter
      ? [{ title: t('tabs.submissions') }]
      : [];

    return (
      <Tabs
        data-hook="my-posts-page-tabs"
        className={classes.root}
        items={[
          { title: t('tabs.publications') },
          ...submissionsTab,
          { title: t('tabs.drafts') },
        ]}
        activeTabIndex={this.tabsByIndex.indexOf(activeTab)}
        onTabClick={this.handleTabClick}
        alignment={alignment}
      />
    );
  }
}

PageTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  fetchPageData: PropTypes.func.isRequired,
  alignment: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  activeTab: getActiveTab(state),
  setActiveTab: actions.setActiveTabPromisified,
  fetchPageData: actions.fetchMyPostsPageDataPromisified,
  isUserGuestWriter: getIsUserGuestWriter(state),
});

export default flowRight(withTranslate, connect(mapRuntimeToProps))(PageTabs);
